<!--  -->
<template>
  <div>
    <!-- 头部 -->
    <div class="header-top8">
      <HeaderTop :tabNum="tabNum"></HeaderTop>
      <p class="header-top8-p1">商业智能技术开发服务商</p>
      <p class="header-top8-p2">您身边的软件技术专家！</p>
    </div>
    <!-- 了解多咖 -->
    <div class="understanding">
      <p class="underDuoka">了解多咖</p>
      <div class="enterprise">
        <img :src="domain+aboutData.image" alt="">
        <div class="enteBox">
          <p class="enteBox-p1">让您的企业</p>
          <p class="enteBox-p2">成为互联网时代下的鲜明个案</p>
          <p class="enteBox-p3">{{aboutData.intro}}</p>
          <p class="enteBox-p4">{{aboutData.productintro}}</p>
        </div>
      </div>
      <div class="development2">
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/1c42cf05-7bad-4610-9e59-0e9b6510be74.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="3" :num="1"></flip-clock>
              <flip-clock :maxNumber="5" :num="0"></flip-clock>
              <div style="line-height: 53rem;font-size: 40rem;">年</div>
            </div>
            <div>深耕软件开发</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/yootj0wusuttmrmd/5631b5af-9a03-4a5b-84f9-e224add6d487.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="4" :num="7"></flip-clock>
              *
              <flip-clock :maxNumber="5" :num="2"></flip-clock>
              <flip-clock :maxNumber="3" :num="4"></flip-clock>
              h
            </div>
            <div>及时响应</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/aef69f0f-185e-4d75-b178-5a7cf04fd980.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="2" :num="1"></flip-clock>
              <flip-clock :maxNumber="3" :num="0"></flip-clock>
              <flip-clock :maxNumber="4" :num="0"></flip-clock>
              %
            </div>
            <div>交付保障</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/06/wKrMZ24eXQ3V050k1md06/x83ym9xezc1pdgvm/fdedac88-570a-4411-bfc2-216d34d88c15.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="2" :num="3"></flip-clock>
              <flip-clock :maxNumber="3" :num="0"></flip-clock>
              <flip-clock :maxNumber="4" :num="0"></flip-clock>
              +
            </div>
            <div>知名企业的选择</div>
          </div>
        </div>
        <div class="deve-item">
          <img src="https://cdn4.codesign.qq.com/screen-slices/2024/05/08/wKrMZ24eXQ3V050k1md08/v0h4gijblrtkzvyi/aaed7a0b-4bb8-4424-adba-ffed43fac4f7.png?imageMogr2/thumbnail/700x650/interlace/1" alt="">
          <div class="deve-into">
            <div class="year">
              <flip-clock :maxNumber="2" :num="5"></flip-clock>
              <flip-clock :maxNumber="3" :num="0"></flip-clock>
              <flip-clock :maxNumber="4" :num="0"></flip-clock>
              +
            </div>
            <div>成功案例</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户合作 -->
    <div class="customer">
      <div class="tpo">
        合作客户
      </div>
      <div class="imgs">
        <img 
        :src="isIndex==index?item+index+'-'+index+'.png':item+index+'.png'"
        v-for="item,index in imgs" :key="index"
        @mouseenter="mouseenter(index)"
        @mouseleave="mouseleave(index)"
        >
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="customer" style="background: #FFFFFF;">
      <div class="tpo">
        联系我们
      </div>
      <div class="address">
        <img src="../assets/img/dt.png" alt="">
        <div class="addressInfo">
          <p class="addressInfo-p1">北京 | 总部</p>
          <p class="addressInfo-p2">E-mail：wjz@duoka361.com</p>
          <p class="addressInfo-p3">电话：13716966004</p>
          <p class="addressInfo-p4">地址：北京朝阳区通惠河南路1081号环宇国际大厦12层</p>
        </div>
      </div>
    </div>
    <FootBottom></FootBottom>
    <FootBottom2 :tabNum="tabNum"></FootBottom2>
  </div>
</template>

<script>
import FlipClock from '@/components/FlipClock.vue';
import HeaderTop from '@/components/HeaderTop.vue'
import FootBottom from '@/components/FootBottom.vue'
import FootBottom2 from '@/components/FootBottom2.vue'
import { getAdvByType,getAboutusData } from '@/assets/js/store.js'
export default {
  data () {
    return {
      tabNum:5,
      // 域名
      domain:'',
      // 头部数据
      headerData:{},
      // 关于我们数据
      aboutData:{},
      // 走马灯的索引
      carouselIndex:0,
      // 合作伙伴的图片
      hzhbImg1:[],
      hzhbImg2:[],
      showRefresh: false,
      aaa:0,
      bbb:0,
      ccc:0,
      imgs:[
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
        'http://preview.www.duoka361.cn/img/about',
      ],
      isIndex:-1,
      
    };
  },

  components: {
    HeaderTop,
    FootBottom,
    FootBottom2,
    FlipClock
  },

  computed: {},
  created(){
    this.getgetAdvByType()
    this.getgetAboutusData()
    // 监听路由变化
    this.$router.beforeEach((to, from, next) =>{
      // 判断是否需要刷新页面
      if(to.path === '/'){
        if(from.path === '/' && this.showRefresh){
            location.reload();
        }
        this.showRefresh = true;
      } else {
        this.showRefresh = false;
      }
      next();
    })
  },
  mounted(){
    // this.getgetAdvByType()
    // this.getgetAboutusData()
    
    
  },
  beforeDestroy() {
  },
  methods: {
    mouseenter(index){
      this.isIndex = index
    },
    mouseleave(index){
      this.isIndex = -1
    },
    // 根据类型获取广告（顶部Banner）
    async getgetAdvByType(){
      const res = await getAdvByType({type:2})
      if(res.data.code == 1){
        this.headerData = res.data.data
      }else{
        this.$message({
          showClose: true,
          message: res.data.msg,
          duration:3000,
          type: 'error'
        });
      }
    },
    // 获取关于我们页数据
    async getgetAboutusData(){
      const res = await getAboutusData()
      if(res.data.code == 1){
        this.aboutData = res.data.data
        this.domain = res.data.data.headpath
        this.hzhbImg1=[]
        this.hzhbImg2=[]
        res.data.data.partner.forEach((item,index) => {
          if(index<=12){
            this.hzhbImg1.push(item)
          }else{
            this.hzhbImg2.push(item)
          }
        });
        res.data.data.partner.forEach((item,index) => {
          if(index<=12){
            this.hzhbImg1.push(item)
          }else{
            this.hzhbImg2.push(item)
          }
        });
      }else{
        this.$message({
          showClose: true,
          message: res.data.msg,
          duration:3000,
          type: 'error'
        });
      }
    },
    change(e,x){
      // e 目前激活的幻灯片的索引，x 原幻灯片的索引
      this.carouselIndex = e
    },
    setActiveItem(num){
      if(num=='next'){
        this.$refs.carousel.setActiveItem(this.carouselIndex+1)
      }else{
        this.$refs.carousel.setActiveItem(this.carouselIndex-1)
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import '../assets/css/about.css';
</style>
